/* You can add global styles to this file, and also import other style files */
@use 'styles/new-design/mixins/layout' as layout;
@use 'styles/new-design/main.scss';

.sc-app-wrapper {
  @include layout.page-spacing();
}

.logo {
  max-height: calc(1.5 * var(--ds-spacing));

  &:hover {
    cursor: pointer;
  }
}

.product-line-icon img {
  margin-left: var(--ds-spacing);
  height: var(--ds-spacing);
}

.text-decoration-underline {
  text-decoration: underline;
}
